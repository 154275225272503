import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Loader, Select, Tooltip } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { useAllocationOfCostsMeasures } from '../../../api/financials-client/financials-client.hooks';
import {
  AllocationOfCostsCategory,
  AllocationOfCostsSummaryV2,
  ViewBy,
} from '../../../api/financials-client/financials-client.type';
import { DownloadPNGButton } from '../../../components/download-png-button.tsx';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import {
  getAllocationOfCostsCategoryV2,
  getAllocationOfCostsUnitV2,
  setAllocationOfCostsCategoryV2,
  setAllocationOfCostsUnitV2,
} from '../../../helpers/storage/storage';
import { newCOLORS } from '../../../styles/colors';
import {
  SmallerHeading,
  StandardText,
} from '../../../styles/new-shared-styled-components/new-shared-styled-components';
import { getScopeNameSuffix } from '../../edit-financial-data/edit-financial-data.helper';
import { FinancialsScope } from '../../financials-scope/financials-scope.type';
import { AllocationOfCostsChart } from './allocation-of-costs-chart';
import { AllocationOfCostsItemList } from './allocation-of-costs-item-list';
import { ShowAllocationOfCostsChartLine } from './allocation-of-costs.type';

const CHART_UNITS = [
  { value: 'cost_per_task', label: 'Tasks in USD' },
  { value: 'cost_per_point', label: 'Points in USD' },
  { value: 'tasks', label: 'Tasks' },
  { value: 'points', label: 'Points' },
];

export const UNCHECKED_COLOR = newCOLORS.lightGray;
const UNDEFINED_COLOR = newCOLORS.gray;

// listed in the opposite order of default order
export const DEFAULT_COLORS = [
  newCOLORS.blue,
  newCOLORS.darkerGreen,
  newCOLORS.lighterGreen,
  newCOLORS.orange,
  newCOLORS.darkYellow,
  newCOLORS.indigo,
  newCOLORS.teal,
  newCOLORS.aqua,
  newCOLORS.magenta,
  newCOLORS.tangerine,
  newCOLORS.violet,
  newCOLORS.purple,
];

export const AllocationOfCosts = ({ startDate, endDate, portfolio, team, setQueryFinished }: FinancialsScope) => {
  const [opened, { toggle }] = useDisclosure(true);
  const [chartUnit, setUnit] = useState<ViewBy>(getAllocationOfCostsUnitV2());
  const [category, setCategory] = useState<AllocationOfCostsCategory>(getAllocationOfCostsCategoryV2());
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const [availableColors, setAvailableColors] = useState<string[]>(Object.assign([], DEFAULT_COLORS));
  const [showAllocationOfCostsChartLines, setShowAllocationOfCostsChartLines] = useState<
    ShowAllocationOfCostsChartLine[]
  >([]);
  const [showError, setShowError] = useState<boolean>(false);
  const errorRef = useClickOutside(() => setShowError(false));

  const updateCategory = (category: AllocationOfCostsCategory) => {
    setAllocationOfCostsCategoryV2(category);
    setCategory(category);
  };

  const updateUnit = (unit: ViewBy) => {
    setAllocationOfCostsUnitV2(unit);
    setUnit(unit);
  };

  const updateShowAllocationOfCostsChartLines = (checked: boolean, field: string, currentColor: string) => {
    // We are doing the below line because we are manually changing the field name from 'undefined' to 'None'
    // in the frontend for display purposes, so the acutal list in the memory is still 'undefined' which won't
    // match the field name in the data we are getting from the backend
    if (field === 'None') {
      field = 'undefined';
    }

    if (availableColors.length == 0 && checked == true) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    if (checked == false && currentColor !== UNCHECKED_COLOR && currentColor !== UNDEFINED_COLOR) {
      setAvailableColors((prevColors) => [...prevColors, currentColor]);
    }

    if (checked == true && availableColors.length == 0 && field.toLowerCase() != 'undefined') {
      return;
    }

    let newColor = UNCHECKED_COLOR;
    if (field.toLowerCase() === 'null') {
      newColor = UNDEFINED_COLOR;
    } else if (checked && availableColors.length > 0) {
      newColor = availableColors.pop() || newCOLORS.red;
    }

    setShowAllocationOfCostsChartLines((prevShowLines) => {
      return prevShowLines.map((line) => {
        if (line.field === field) {
          return {
            ...line,
            show: checked,
            color: checked ? newColor || UNCHECKED_COLOR : UNCHECKED_COLOR,
          };
        }
        return line;
      });
    });
  };

  const initShowAllocationOfCostsChartLines = (data: AllocationOfCostsSummaryV2[]) => {
    setShowAllocationOfCostsChartLines(
      data.map((item) => {
        let color = UNCHECKED_COLOR;
        if (item.field.toLowerCase() === 'null') {
          color = UNDEFINED_COLOR;
        } else if (availableColors.length > 0) {
          color = availableColors.pop() || newCOLORS.green;
        }
        return {
          field: item.field,
          show: item.field.toLowerCase() === 'null' ? false : availableColors.length > 0,
          color,
        } as ShowAllocationOfCostsChartLine;
      })
    );
  };

  useEffect(() => {
    if (category) {
      setAvailableColors(Object.assign([], DEFAULT_COLORS));
    }
  }, [category, startDate, endDate, portfolio, team]);

  const { chartData, query } = useAllocationOfCostsMeasures(
    {
      portfolioId: portfolio?.id || null,
      teamId: team?.id || null,
      startDate: startDate,
      endDate: endDate,
    },
    {
      enabled: !!portfolio?.id && !!category && !!startDate && !!endDate,
    }
  );

  useEffect(() => {
    if (chartData && category in chartData) {
      initShowAllocationOfCostsChartLines(
        Object.keys(chartData[category as AllocationOfCostsCategory].options).map(
          (k) => ({ field: k } as AllocationOfCostsSummaryV2)
        )
      );

      setQueryFinished?.(true);
    } else {
      initShowAllocationOfCostsChartLines([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, category]);

  return (
    <AllocationOfCostsContainer ref={downloadRef}>
      <CollapseHeader>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <CollapseIcon>
            {opened ? (
              <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
            ) : (
              <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
            )}
          </CollapseIcon>
          <SmallerHeading>Allocation of costs{getScopeNameSuffix(team, portfolio)}</SmallerHeading>
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          {opened && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StandardText>Value</StandardText>
              <Select
                label=""
                size="xs"
                style={{ width: 125, paddingLeft: '5px', paddingRight: '10px' }}
                value={chartUnit as ViewBy}
                data={CHART_UNITS}
                onChange={(allocationOfCostsUnit) => updateUnit(allocationOfCostsUnit as ViewBy)}
                allowDeselect={false}
              />
            </div>
          )}
          <div ref={exceptionRef}>
            {opened && (
              <DownloadPNGButton
                handleDownload={() => canvasHtmlDownload('Allocation of costs', downloadRef, exceptionRef)}
                exceptionRef={exceptionRef}
              />
            )}
          </div>
        </div>
      </CollapseHeader>
      <Collapse in={opened} ref={errorRef}>
        <CollapseContent>
          <Select
            label=""
            size="xs"
            placeholder="Select category"
            style={{ width: 150, padding: '10px' }}
            value={category}
            data={Object.values(AllocationOfCostsCategory).map((category) => ({
              value: category,
              label: category.replace('_', ' '),
            }))}
            onChange={(category) => updateCategory(category as AllocationOfCostsCategory)}
            allowDeselect={false}
          />
          {showError && (
            <StandardText style={{ paddingLeft: '10px', color: 'red' }}>
              Chart can only display 12 defined values at a time
            </StandardText>
          )}
          {query.isFetched && chartData?.[category as AllocationOfCostsCategory] ? (
            <AllocationOfCostsDataContainer>
              <AllocationOfCostsItemBox>
                <AllocationOfCostsItemHeader>
                  <div style={{ width: 125, textAlign: 'right', fontSize: '11px', fontWeight: 500 }}>
                    TOTAL{' '}
                    <Tooltip
                      multiline
                      w={300}
                      position="right"
                      label={'Total dollars for the date range selected'}
                      style={{ textAlign: 'left' }}
                    >
                      <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                    </Tooltip>
                  </div>
                  <div style={{ width: 125, textAlign: 'right', fontSize: '11px', fontWeight: 500 }}>
                    AVERAGE{' '}
                    <Tooltip
                      multiline
                      w={300}
                      position="right"
                      label={'Total dollars divided by number of months from the range selected'}
                      style={{ textAlign: 'left' }}
                    >
                      <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                    </Tooltip>
                  </div>
                </AllocationOfCostsItemHeader>
                <AllocationOfCostsItemList
                  totalSummary={Object.entries(chartData[category as AllocationOfCostsCategory].options).map(
                    ([k, v]) => ({
                      field: k,
                      ...v.total,
                    })
                  )}
                  averageSummary={Object.entries(chartData[category as AllocationOfCostsCategory].options).map(
                    ([k, v]) => ({
                      field: k,
                      ...v.average,
                    })
                  )}
                  value={chartUnit}
                  showAllocationOfCostsChartLines={showAllocationOfCostsChartLines}
                  updateShowChartLines={updateShowAllocationOfCostsChartLines}
                />
              </AllocationOfCostsItemBox>
              <div style={{ width: '99%' }}>
                <AllocationOfCostsChart
                  showAllocationOfCostsChartLines={showAllocationOfCostsChartLines}
                  chartData={chartData[category as AllocationOfCostsCategory].chart_data}
                  viewBy={chartUnit as ViewBy}
                  category={category}
                  portfolioId={portfolio?.id}
                />
              </div>
            </AllocationOfCostsDataContainer>
          ) : (
            <LoaderContainer>
              <Loader color={newCOLORS.indigo} size="lg" />
            </LoaderContainer>
          )}
        </CollapseContent>
      </Collapse>
    </AllocationOfCostsContainer>
  );
};

const AllocationOfCostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const AllocationOfCostsItemBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const AllocationOfCostsItemHeader = styled.div`
  width: 400px;
  display: flex;
  justify-content: end;
`;

const CollapseContent = styled.div`
  padding: 12px;
  gap: 1em;
  height: 475px;
`;

const AllocationOfCostsDataContainer = styled.div`
  display: flex;
  padding: 12px;
  gap: 1em;
  height: 400px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 375px;
`;

const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;
